import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col xs="12" className="mb-5">
            <div className="text-center">
              <Title>Why Napa Vertical </Title>
            </div>
          </Col>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Title variant="card" fontSize="24px">
                is the Right Choice for You
              </Title>
              <Text variant="small" my={4}>
                Our cloud-based management software gives you the freedom and
                control to streamline business processes anywhere using any
                device. What’s more, our facility management software is
                designed according to strict functionality, ease of use, and
                customization principles to ensure a smooth implementation
                process.
              </Text>
              <Text variant="small">
                From space planning and management, to asset monitoring, capital
                control, KPIs, and so much more, benefit from a system that
                increases team communication while providing a bird’s-eye view
                of your business operations.
              </Text>
              <Text variant="small" mt={4}>
                {" "}
                Unmatched support, functionality, and ease of use are just some
                of the elements that make Napa Vertical the ideal provider for
                your{" "}
                <a href="/" target="_blank">
                  <Span color="secondary"> facility management software</Span>
                </a>{" "}
                solutions. This is where strategic management starts, functions,
                and propels your business into optimal productivity.{" "}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;

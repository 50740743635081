import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box, Text } from "../../components/Core";

import iconLayout from "../../assets/image/png/icon-layout.png";
import iconLayers from "../../assets/image/png/icon-layers.png";
import iconResponsive from "../../assets/image/png/icon-responsive.png";

const BoxStyled = styled(Box)`
  border-bottom: 1px solid #eae9f2;
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest} className="d-flex flex-column align-items-center">
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box className="text-center">
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    <BoxStyled bg="#f6f6f8" pb={["50px!important"]}>
      <Container>
        <Row className="justify-content-center">
          <Title>Why CAFM software is Your Go-To</Title>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard
              title="Asset Productivity Enhancement & Sustainability:"
              iconImage={iconLayout}
            >
              In order to reduce downtime, you need to nip an issue in the bud
              before it has a chance to manifest into a larger problem. CAFM
              software is the solution that avoids costly downtime through
              preventive maintenance management systems.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title=" Integrated Operations:" iconImage={iconLayers}>
              Using CAFM software means opening all communication channels
              within your business. Now, you have a centralized space where you
              can collect data, manage and monitor assets and equipment,
              evaluate building requirements, oversee capital, and view
              personalized KPIs on a user-friendly dashboard.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard
              title=" Proactive Scheduling:"
              iconImage={iconResponsive}
            >
              Easily allocate important tasks to the best teams for each
              project. With strategic prioritization tools, you can set
              deadlines for the coming weeks, months, or even years, while
              evaluating KPIs and project progress.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </BoxStyled>
  </>
);

export default Feature;
